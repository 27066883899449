<template>
  <div>
    <b-card title="Retrouvez tous les produits qui n'ont récupéré aucun tracking de la part d'Agediss. Possible problème de dispatch.">
      <b-row>
        <b-col
          cols="12 mb-4"
        >
          <div class="px-2 py-1 navbar-light header-navbar rounded-lg shadow d-flex align-items-center justify-content-start">
            <feather-icon
              icon="SearchIcon"
              class="mr-50 text-primary"
              size="20"
            />
            <b-input-group class="input-group-merge shadow-none">
              <b-form-input
                v-model="code"
                placeholder="Rechercher un sku..."
                style="height: auto;"
                class="border-0 shadow-none"
                @input="filter"
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <b-row v-else class="match-height">
        <b-table
          ref="selectableTable"
          selectable
          :items="orders"
          :fields="fields"
        >
          <!-- <template #cell(disableError)="data">
            <feather-icon
              icon="TrashIcon"
              size="14"
              @click="orders = orders.filter((item) => item.number !== data.item.number)"
            />
          </template> -->
        </b-table>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BSpinner,
  BFormInput,
  BInputGroup,
  // BBadge,
  BTable,
  VBPopover,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormInput,
    BCard,
    BSpinner,
    BRow,
    BCol,
    BTable,
    // BBadge,
    BInputGroup,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      required,
      baseLogistics: [],
      orders: [],
      products: [],
      categories: [],
      logistic: {},
      conditionalProducts: '',
      conditionalCategories: '',
      types: [{ name: 'Remise fixe', type: 'fixed_cart' }, { name: 'Remise en pourcentage', type: 'percent' }],
      individualType: [{ name: 'Oui', type: true }, { name: 'Non', type: false }],
      conditions: [{ name: 'OU', type: 'or' }, { name: 'ET', type: 'and' }],
      code: '',
      fields: [{ key: 'number', label: 'Commande' }, { key: 'name', label: 'Produit' }, { key: 'sku', label: 'SKU' }, { key: 'date', label: 'Date dispatch' }, { key: 'logistic', sortable: true }, { key: 'shipper', label: 'Livreur' }],
      showModal: false,
      categoryInformations: [],
      loading: false,
    }
  },
  async mounted() {
    try {
      await this.getLogistics()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async getLogistics() {
      try {
        const {
          orders,
        } = await this.$http.get('/admin/logistic/warning')
        this.orders = orders
        this.baseLogistics = orders
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    filter() {
      this.filterQuery = this.code
      if (!this.code || this.code === '') {
        this.orders = this.baseLogistics
      } else {
        const value = this.code
        console.log(value)
        this.orders = this.baseLogistics.filter(logistic => logistic.sku?.toLowerCase().includes(value.toLowerCase()))
      }
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
